import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { getWidthForInput } from '../../../utils/DataCapturingTableWidth.utils';
import {
  CurrentColumnKeys,
  InputType,
  PositionType,
  TableAuditPositionRow,
  TableDateField,
  TableDescriptionField,
  TableNumberField,
  WordRect,
} from '../../../models/interfaces';
import { NgClass } from '@angular/common';
import { TableItemComponent } from './table-item/table-item.component';
import { WaMutationObserver } from '@ng-web-apis/mutation-observer';
import { FieldOverlayStoreService } from '../../../services/overlay/field-overlay-store.service';
import { TableOverlayStoreService } from '../../../services/overlay/table-overlay-store.service';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [NgClass, TableItemComponent, WaMutationObserver],
  templateUrl: './table.component.html',
})
export class TableComponent {
  @Input({ required: true }) tableHeader: string[] = [];
  @Input({ required: true }) tableRows: TableAuditPositionRow[] = [];
  @Input() isTableLayoutEditable = true;
  @Input({ required: true }) inputTypes!: Record<string, InputType>;
  @Input() openedDropdownIndex!: number | undefined;
  @Input({ required: true }) consideredPropertyKeys!: (keyof TableAuditPositionRow)[];
  @Input({ required: true }) isTableExtractionView!: boolean;
  @Input({ required: true }) isPositionClassificationView!: boolean;
  @Output() tableElementsChanged = new EventEmitter();
  @Output() rowAdded = new EventEmitter<{
    index: number;
    tableId: string | undefined;
    pageId: string | undefined;
  }>();
  @Output() addRowAtIndexEmit = new EventEmitter<number>();
  @Output() rowDeleted = new EventEmitter<number>();
  @Output() updateCurrentFieldIndex = new EventEmitter<number>();
  @Output() setRowData = new EventEmitter<{ event: Event; header: string; rowIndex: number }>();
  @Output() suggestedSumInputActive = new EventEmitter<boolean>();
  @Output() setIsCtrlPressed = new EventEmitter<boolean>();
  @Output() dropDownValueSet = new EventEmitter<{
    positionType: PositionType;
    header: string;
    rowIndex: number;
  }>();
  @Output() setDropdownIndex = new EventEmitter<number>();

  @ViewChild('table') table!: ElementRef<HTMLDivElement>;

  private readonly fieldOverlayStoreService = inject(FieldOverlayStoreService);
  private readonly tableOverlayStoreService = inject(TableOverlayStoreService);

  constructor() {
    effect(() => {
      if (this.currentFieldIndex() === undefined) return;

      const headerInTable = this.tableHeader[this.currentFieldIndex()! % this.tableHeader.length];

      const currentTableRow =
        this.tableRows[Math.floor(this.currentFieldIndex()! / this.tableHeader.length)];

      const field = this.getFieldArray(this.tableRows)[this.currentFieldIndex()!];

      if (!this.fieldOverlayStoreService.setNewHighlight(field.id, false)) {
        this.setTableHighlight(currentTableRow, headerInTable);
        return;
      }

      this.tableOverlayStoreService.tableHighlightByTableId$.next({});
    });
  }

  private getColumnIndex(headerInTable: string, headers: (string | undefined)[]) {
    if (this.isPositionClassificationView && headerInTable === 'Art') {
      return headers.findIndex((header) => {
        return header === 'Beschreibung';
      });
    }

    return headers.findIndex((header) => header === headerInTable);
  }

  setTableHighlight(row: TableAuditPositionRow, headerInTable: string) {
    this.tableOverlayStoreService.clearAllTableHighlights();
    const relatedTableRowIndex = row.originalTableRowIndex;

    if (!row.tableId || !row.pageId || relatedTableRowIndex === undefined) {
      return;
    }

    const tableData = structuredClone(
      this.tableOverlayStoreService.getTablesOnPage(row.pageId).find((tableData) => {
        return tableData.id === row.tableId;
      })!,
    );

    const columnIndex = this.getColumnIndex(headerInTable, tableData.columnHeaders);
    if (columnIndex < 0 || columnIndex >= tableData.lines.vertical.length - 1) {
      return;
    }

    const [xStart, xEnd] = [
      tableData.lines.vertical[headerInTable === 'Art' ? 0 : columnIndex].offset,
      tableData.lines.vertical[
        headerInTable === 'Art' ? tableData.lines.vertical.length - 1 : columnIndex + 1
      ].offset,
    ];
    const [yStart, yEnd] = [
      tableData.lines.horizontal[relatedTableRowIndex].offset,
      tableData.lines.horizontal[relatedTableRowIndex + 1].offset,
    ];

    const wordRect = {
      x: [xStart, xEnd],
      y: [yStart, yEnd],
    } as WordRect;

    this.tableOverlayStoreService.setNewHighlight(row.tableId, wordRect);
  }

  // TODO: Refactor window:* key handling
  currentFieldIndex: WritableSignal<number | undefined> = signal(0);

  getTableElement(): HTMLDivElement {
    return this.table.nativeElement;
  }

  getPropertiesOfCurrentField(): [number, CurrentColumnKeys] | undefined {
    if (this.currentFieldIndex() === undefined) return undefined;

    const currInput = this.getInputElement(this.currentFieldIndex()!)!;

    if (!currInput.matches('input')) return undefined;
    const inputElement = currInput as HTMLInputElement;
    const index = inputElement.dataset['row'] as unknown as number;
    const headerKey = inputElement.dataset['header'] as CurrentColumnKeys;

    return [index, headerKey];
  }

  setNextField() {
    if (this.currentFieldIndex() === undefined) return;
    (this.getInputElement(this.currentFieldIndex()!)! as HTMLElement).blur();

    const nextFieldIndex = this.getNextFieldIndex();

    const noNextField = nextFieldIndex === -1;
    if (noNextField) {
      this.addRowAtIndexEmit.emit(this.tableRows.length);
      this.activateTableFieldAtIndex(this.currentFieldIndex()! + 1);

      return;
    }

    this.activateTableFieldAtIndex(nextFieldIndex!);
    (this.getInputElement(this.currentFieldIndex()!)! as HTMLElement).focus();
  }

  protected activateTableFieldAtIndex(index: number) {
    this.currentFieldIndex.set(index);
    this.suggestedSumInputActive.emit(false);
  }

  getFieldArray(
    tableRows: TableAuditPositionRow[],
  ): (TableDescriptionField | TableDateField | TableNumberField)[] {
    return tableRows.flatMap((row) => {
      const consideredProperties = this.consideredPropertyKeys.reduce(
        (obj, key) => {
          obj[key] = row[key];
          return obj;
        },
        {} as Record<keyof TableAuditPositionRow, unknown>,
      );

      return Object.values(
        consideredProperties as Record<
          keyof TableAuditPositionRow,
          TableDescriptionField | TableDateField | TableNumberField
        >,
      );
    });
  }

  getNextFieldIndex(): number | undefined {
    if (this.currentFieldIndex() === undefined) return undefined;

    const nextFieldIndex = this.getFieldArray(this.tableRows)
      .slice(this.currentFieldIndex()! + 1)
      .findIndex((field) => {
        return !field.value || field.validationError;
      });

    if (nextFieldIndex === -1) return nextFieldIndex;

    return nextFieldIndex + this.currentFieldIndex()! + 1;
  }

  onTableChange(): void {
    if (this.currentFieldIndex() === undefined) return;
    if (this.getNextFieldIndex() === -1) return;

    const element = this.getInputElement(this.currentFieldIndex()!);
    if (!element) return;

    if (!element.matches('input')) return;

    const input = element as HTMLInputElement;
    input.focus();
    input.setSelectionRange(0, 0);
    input.scrollLeft = 0;
  }

  private getInputElement(index: number): Element | undefined {
    // TODO: select nth - child?
    return this.getTableElement().querySelectorAll('.table-input')[index];
  }
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.setIsCtrlPressed.emit(true);
    }

    if (event.code === 'Enter') {
      this.setNextField();
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.setIsCtrlPressed.emit(false);
    }
  }

  emitDropDownValueSet(event: { positionType: PositionType; header: string; rowIndex: number }) {
    this.tableOverlayStoreService.tableHighlightByTableId$.next({});
    this.fieldOverlayStoreService.fieldHighlight$.next(undefined);
    this.dropDownValueSet.emit(event);
  }

  protected readonly getWidthForInput = getWidthForInput;
}

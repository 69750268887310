import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { SharedModule } from 'primeng/api';
import { NgStyle } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [MatIcon, SharedModule, NgStyle, ConfirmDialogModule],
  templateUrl: './dialog.component.html',
})
export class DialogComponent {
  @Input() closeText = 'Abbrechen';
  @Input() acceptText = 'Bestätigen';
  @Input({ required: true }) title = '';
  @Output() closeDialog = new EventEmitter<void>();
  @Output() acceptDialog = new EventEmitter<void>();
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NgStyle } from '@angular/common';
import { TableData } from '../../../../../models/interfaces';

@Component({
  selector: 'app-table-overlay-context-menu',
  standalone: true,
  imports: [MatIcon, NgStyle],
  templateUrl: './table-overlay-context-menu.component.html',
})
export class TableOverlayContextMenuComponent {
  @Input({ required: true }) tableData!: TableData;

  @Output() deleteButtonClicked = new EventEmitter<void>();
  @Output() editButtonClicked = new EventEmitter<void>();
  @Output() magicWandButtonClicked = new EventEmitter<void>();
}

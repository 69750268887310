import {
  Component,
  computed,
  inject,
  Input,
  OnChanges,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { ExtractedTableCreationService } from '../../../services/extracted-data/extracted-table-creation.service';
import { TableComponent } from '../../ui/table/table.component';
import { ToolbarService } from '../../../services/app-state/toolbar.service';
import { NgClass } from '@angular/common';
import {
  AppView,
  InputType,
  positionExtractionHeaders,
  TableAuditPositionRow,
  TableDateField,
  TableDescriptionField,
  tableHeaderConstants,
  TableNumberField,
} from '../../../models/interfaces';
import { OcrSelectionService } from '../../../services/ocr/ocr-selection.service';
import { ConfirmService } from '@l21s-ecnps/gui-commons';
import { ExtractedTableRemovalService } from '../../../services/extracted-data/extracted-table-removal.service';
import { ExtractedDataStoreService } from '../../../services/extracted-data/extracted-data-store.service';
import { ButtonStateService } from '../../../services/app-state/button-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { parseCurrency } from '../../../utils/ParseCurrency.utils';
import { FormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { MatIcon } from '@angular/material/icon';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogComponent } from '../../dialogs/dialog/dialog.component';
import { ConfirmationService } from 'primeng/api';
import { getDefaultInputClasses } from '../../../utils/InputValidation.utils';
import { MatTooltip } from '@angular/material/tooltip';
import { formatMoney } from '../../../utils/FormatMoney.utils';
import { CtDocument } from '../../../models/ct-batch-model';
import { WorkflowService } from '../../../services/app-state/workflow.service';
import { TableParseService } from '../../../services/table/table-parse.service';
import { FieldOverlayStoreService } from '../../../services/overlay/field-overlay-store.service';
import dayjs from 'dayjs';

@UntilDestroy()
@Component({
  selector: 'app-data-capturing-table-view',
  standalone: true,
  templateUrl: './data-capturing-table-view.component.html',
  imports: [
    TableComponent,
    NgClass,
    FormsModule,
    InputNumberModule,
    MatIcon,
    ConfirmDialogModule,
    DialogComponent,
    MatTooltip,
  ],
})
export class DataCapturingTableViewComponent implements OnInit, OnChanges {
  private buttonStateService = inject(ButtonStateService); //
  private extractedTableRemovalService = inject(ExtractedTableRemovalService); //
  private extractedTableCreationService = inject(ExtractedTableCreationService); //
  private toolbarService = inject(ToolbarService); //
  private ocrSelectionService = inject(OcrSelectionService); //
  private confirmService = inject(ConfirmService); //
  private extractedDataStoreService = inject(ExtractedDataStoreService); //
  private confirmationService = inject(ConfirmationService);
  protected workflowService = inject(WorkflowService);
  private tableParseService = inject(TableParseService);
  private fieldOverlayStoreService = inject(FieldOverlayStoreService);

  @Input({ required: true }) document!: CtDocument;

  @ViewChild('dataCapturingTableComponent')
  dataCapturingTableComponent?: TableComponent;

  tableRows: TableAuditPositionRow[] = [];
  buildTable!: boolean;
  isCtrlPressed = false;

  // TODO: extract this somehow..
  protected suggestedSumOfPositions: WritableSignal<{
    value?: string | number;
    validationError?: string;
  }> = signal({
    value: undefined,
    validationError: undefined,
  });
  protected calculatedSumOfPositions: WritableSignal<number> = signal(0);
  protected areSumsDiverging = computed(
    () =>
      this.suggestedSumOfPositions().value !== undefined &&
      this.suggestedSumOfPositions().validationError === undefined &&
      this.suggestedSumOfPositions().value !== this.calculatedSumOfPositions(),
  );

  protected suggestedSumInputActive = false;

  ngOnInit() {
    this.loadCachedPositions();

    this.buttonStateService.setNextButtonState({
      label: 'Weiter',
      tooltip: 'Bitte vervollständige bzw. ergänze deine Eingaben',
      isActive: () => {
        return true;
      },
      action: () => {
        this.openNextDocument();
      },
    });

    this.extractedTableCreationService.createdTable$
      .pipe(untilDestroyed(this))
      .subscribe((tableRows) => {
        this.buildTable = true;
        // TODO: this is obviously only temporary and has to be changed when the columns are labelled
        // TODO: validation
        if (tableRows.length > 0) {
          this.updateTableRows([
            ...this.tableRows,
            ...tableRows.map((row) => {
              return {
                description: row.description,
                from: row.from,
                to: row.to,
                amount: row.amount,
                tableId: row.tableId,
                pageId: row.pageId,
                positionType: row.positionType,
                originalTableRowIndex: row.originalTableRowIndex,
              };
            }),
          ]);
        }
      });

    this.ocrSelectionService.selectedWordsData$
      .pipe(untilDestroyed(this))
      .subscribe((selectedWordsData) => {
        if (this.toolbarService.isTableCreationEnabled()) return;
        const joinedString = selectedWordsData.words.join(' ');

        // TODO: handle this in a more general way to allow arbitrary non-table-inputs to receive ocr selection data
        if (!this.dataCapturingTableComponent) return;
        if (this.dataCapturingTableComponent.getPropertiesOfCurrentField() !== undefined) {
          const [index, headerKey] =
            this.dataCapturingTableComponent.getPropertiesOfCurrentField()!;

          this.updateTableRows(
            this.tableParseService.handleTableHeaders(
              headerKey,
              joinedString,
              index,
              this.isCtrlPressed,
            ),
          );

          const objectKey = Object.entries(tableHeaderConstants).find((pair) => {
            return pair[1] === headerKey;
          })![0] as keyof TableAuditPositionRow;

          const fieldId = (
            this.tableRows[index][objectKey] as
              | TableDescriptionField
              | TableDateField
              | TableNumberField
          ).id;

          if (this.isCtrlPressed) {
            this.fieldOverlayStoreService.appendExtractedFieldLocations(
              fieldId,
              selectedWordsData.wordRects,
              selectedWordsData.pageId,
            );
          } else {
            this.fieldOverlayStoreService.setExtractedFieldLocations(
              fieldId,
              selectedWordsData.wordRects,
              selectedWordsData.pageId,
            );
          }

          this.fieldOverlayStoreService.setNewHighlight(fieldId, false);
        } else if (this.suggestedSumInputActive) {
          this.updateSuggestedSum(joinedString);
          this.suggestedSumInputActive = false;
        }
      });

    this.extractedTableRemovalService.destroyedTable$
      .pipe(untilDestroyed(this))
      .subscribe(({ tableId, pageId }) => {
        this.removeMatchingTableRows(tableId, pageId);
      });

    this.toolbarService.setTableCreationEnabled(true);
  }

  ngOnChanges(): void {
    this.loadCachedPositions();
  }

  emptyRow = (tableId: string | undefined, pageId: string | undefined): TableAuditPositionRow => {
    return {
      description: { id: crypto.randomUUID() },
      from: { id: crypto.randomUUID() },
      to: { id: crypto.randomUUID() },
      amount: { id: crypto.randomUUID() },
      positionType: { id: crypto.randomUUID() },
      tableId: tableId,
      documentId: this.document.id,
      pageId: pageId,
    };
  };

  startManualCreation() {
    this.buildTable = true;
    this.toolbarService.setTableCreationEnabled(false);
    this.updateTableRows([...this.tableRows, structuredClone(this.emptyRow('temporary_id', ''))]);
  }

  removeMatchingTableRows(tableId: string, pageId: string): void {
    this.removeFieldHighlightData(
      this.tableRows.filter((row) => {
        return row.pageId === pageId && row.tableId === tableId;
      }),
    );
    this.updateTableRows(
      this.tableRows.filter(
        (row) => row.pageId !== pageId || (row.pageId === pageId && row.tableId !== tableId),
      ),
    );
    this.storeValues();
  }

  storeValues() {
    this.extractedDataStoreService.setAuditPositionArray(
      this.getNotEmptyTableRows(),
      this.document.id,
    );
  }

  private removeFieldHighlightData(rows: TableAuditPositionRow[]) {
    rows.forEach((row) => {
      [row.description, row.from, row.to, row.amount].forEach((field) => {
        this.fieldOverlayStoreService.deleteFieldLocations(field.id);
      });
    });

    this.fieldOverlayStoreService.fieldHighlight$.next(undefined);
  }

  private loadCachedPositions(): void {
    const auditPositions = this.extractedDataStoreService.auditPositionArray.filter(
      (position) => position.documentId === this.document.id,
    );
    this.buildTable = auditPositions.length > 0;

    if (auditPositions.length <= 0) {
      return;
    }
    //TODO: persist id
    this.updateTableRows(
      auditPositions.map((auditPosition) => ({
        description: {
          value: auditPosition.description.value,
          id: auditPosition.description.id,
        },
        from: {
          value: auditPosition.from.value,
          id: auditPosition.from.id,
        },
        to: {
          value: auditPosition.to.value,
          id: auditPosition.to.id,
        },
        amount: {
          value: auditPosition.originalAmount.value,
          id: auditPosition.originalAmount.id,
        },
        positionType: {
          value: auditPosition.type.value,
          id: auditPosition.type.id,
        },
        tableId: auditPosition.tableId,
        pageId: auditPosition.pageId,
      })),
    );

    this.applyValidation();
  }

  private applyValidation() {
    this.tableRows.forEach((row) => {
      if (!row.description.value)
        row.description.validationError = 'Der Wert ist keine gültige description';
      if (!row.from.value || !dayjs(row.from.value).isValid())
        row.from.validationError = 'Der Wert ist kein gültiges Datum';
      if (!row.to.value || !dayjs(row.to.value).isValid())
        row.to.validationError = 'Der Wert ist kein gültiges Datum';
      if (!row.amount.value) row.amount.validationError = 'Der Wert ist kein gültiger amount';
    });
  }

  addRowAtIndex(
    index: number,
    tableId: string | undefined = 'temporary_id',
    pageId: string | undefined = '',
  ): void {
    const { newTableId, newPageId } = this.determineTableBelonging(index, tableId, pageId);
    this.updateTableRows(
      this.tableRows.toSpliced(index, 0, structuredClone(this.emptyRow(newTableId, newPageId))),
    );

    this.storeValues();
  }

  private determineTableBelonging(
    rowIndex: number,
    tableId: string | undefined,
    pageId: string | undefined,
  ) {
    const previousRow: TableAuditPositionRow | undefined = this.tableRows[rowIndex - 1] as
      | TableAuditPositionRow
      | undefined;

    if (!previousRow?.pageId) {
      return { newTableId: 'temporary_id', newPageId: '' };
    }

    if (previousRow.pageId !== pageId || previousRow.tableId !== tableId) {
      return { newTableId: 'temporary_id', newPageId: '' };
    }
    return { newTableId: tableId, newPageId: pageId };
  }

  removeRowAtIndex(index: number): void {
    this.removeFieldHighlightData([this.tableRows[index]]);
    this.updateTableRows(this.tableRows.toSpliced(index, 1));
    this.storeValues();

    if (this.tableRows.length === 0) {
      this.buildTable = false;
    }
  }

  openNextDocument() {
    if (!this.buildTable) {
      this.workflowService.navigateToNextView();
      return;
    }
    if (!this.dataCapturingTableComponent) return;
    const filteredFields = this.dataCapturingTableComponent.getFieldArray(
      this.getNotEmptyTableRows(),
    );
    if (filteredFields.some((field) => !field.value && field.validationError)) {
      this.confirmService.snackError('Füllen Sie alle Felder korrekt aus.');
      return;
    }

    if (this.areSumsDiverging()) {
      this.confirmationService.confirm({
        accept: () => {
          this.workflowService.navigateToNextView(filteredFields.length > 0);
        },
      });
    } else {
      this.workflowService.navigateToNextView(filteredFields.length > 0);
    }
  }

  getNotEmptyTableRows() {
    return this.tableRows.filter((row) => {
      let isEmpty = true;

      if (row.amount.value !== undefined) {
        isEmpty = false;
      }
      if (row.from.value !== undefined) {
        isEmpty = false;
      }
      if (row.to.value !== undefined) {
        isEmpty = false;
      }
      if (row.description.value !== undefined && row.description.value !== '') {
        isEmpty = false;
      }
      return !isEmpty;
    });
  }

  setRowDataAtIndex(event: Event, key: string, index: number): void {
    this.updateTableRows(
      this.tableParseService.handleTableHeaders(
        key,
        this.getInputValue(event),
        index,
        this.isCtrlPressed,
      ),
    );
  }

  private updateTableRows(tableRows: TableAuditPositionRow[]) {
    this.tableRows = tableRows;
    this.tableParseService.tableRows = tableRows;
    this.storeValues();
    this.recalculateSumOfPositions();

    if (this.tableRows.length === 0) {
      this.buildTable = false;
    }
  }

  protected recalculateSumOfPositions() {
    this.calculatedSumOfPositions.set(
      // TODO: Maybe we should store all money values in cents to avoid rounding issues here
      Number(
        (
          Math.round(
            this.tableRows
              .map((row) => (row.amount.validationError ? 0 : (row.amount.value ?? 0)))
              .reduce((sum, value) => sum + value, 0) * 100,
          ) * 0.01
        ).toFixed(2),
      ),
    );
  }

  getInputValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  updateSuggestedSum(stringValue: string) {
    console.log(stringValue);

    const parsedNumber = stringValue === '' ? undefined : parseCurrency(stringValue);
    const isInvalid = parsedNumber !== undefined && isNaN(parseFloat(stringValue));

    this.suggestedSumOfPositions.set({
      value: isInvalid ? stringValue : parsedNumber,
      validationError: isInvalid ? 'Der Wert ist kein gültiger amount' : undefined,
    });
  }

  protected readonly console = console;
  protected readonly getInputClasses = getDefaultInputClasses;

  protected getSuggestedSumDisplayValue(): string {
    const suggestedSum = this.suggestedSumOfPositions().value;
    return (
      (suggestedSum !== undefined && typeof suggestedSum === 'number'
        ? formatMoney(suggestedSum)
        : suggestedSum) ?? ''
    );
  }

  protected readonly positionExtractionHeaders = positionExtractionHeaders;
  protected inputTypes = {
    [tableHeaderConstants.description]: InputType.INPUT,
    [tableHeaderConstants.from]: InputType.INPUT,
    [tableHeaderConstants.to]: InputType.INPUT,
    [tableHeaderConstants.amount]: InputType.INPUT,
  };
  protected readonly AppView = AppView;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SharedModule } from 'primeng/api';
import { TableData } from '../../../models/interfaces';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-edit-dialog',
  standalone: true,
  imports: [ConfirmDialogModule, SharedModule, DialogComponent],
  templateUrl: './edit-dialog.component.html',
})
export class EditDialogComponent {
  @Input({ required: true }) tableData!: TableData;

  @Output() acceptedDialog = new EventEmitter<void>();
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-toolbar-button',
  standalone: true,
  imports: [NgClass, TooltipModule, NgStyle],
  templateUrl: './toolbar-button.component.html',
})
export class ToolbarButtonComponent {
  @Input({ required: true }) iconName!: string;
  @Input() additionalClasses = '';
  @Input() disabled = false;

  @Output() buttonClick = new EventEmitter<void>();
  @Input() isActive = false;
}

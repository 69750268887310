import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TableOverlayRowRemoveContainerComponent } from './table-overlay-row-remove-container/table-overlay-row-remove-container.component';
import { TableData } from '../../../../../models/interfaces';

@Component({
  selector: 'app-table-overlay-row',
  standalone: true,
  imports: [TableOverlayRowRemoveContainerComponent],
  templateUrl: './table-overlay-row.component.html',
  styles: [
    `
      :host {
        @apply pointer-events-none flex w-full justify-center;
      }
    `,
  ],
})
export class TableOverlayRowComponent {
  @Input({ required: true }) tableData!: TableData;
  @Input({ required: true }) isIgnored!: boolean;
  @Input({ required: true }) rowIndex!: number;
  @Output() toggleIgnoreForRow = new EventEmitter<void>();

  @HostBinding('style.top') get top() {
    return;
  }

  @HostBinding('class.bg-transparent') get transparent() {
    return this.isIgnored;
  }

  @HostBinding('class.bg-[#78D8ED26]') get opaque() {
    return !this.isIgnored;
  }

  @HostBinding('style.height') get height(): string {
    const start = this.tableData.lines.horizontal.at(0)!.offset;
    const end = this.tableData.lines.horizontal.at(-1)!.offset;
    const height =
      this.tableData.lines.horizontal[this.rowIndex + 1].offset -
      this.tableData.lines.horizontal[this.rowIndex].offset;

    const normalizedHeight = height / (end - start);

    return `${normalizedHeight * 100}%`;
  }
}

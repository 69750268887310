import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AppView,
  InputType,
  positionClassificationHeaders,
  PositionType,
  TableAuditPositionRow,
  tableHeaderConstants,
} from '../../../models/interfaces';
import { TableComponent } from '../../ui/table/table.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OcrSelectionService } from '../../../services/ocr/ocr-selection.service';
import { TableParseService } from '../../../services/table/table-parse.service';
import { ExtractedDataStoreService } from '../../../services/extracted-data/extracted-data-store.service';
import { ButtonStateService } from '../../../services/app-state/button-state.service';
import { CtDocument } from '../../../models/ct-batch-model';
import { ToolbarService } from '../../../services/app-state/toolbar.service';
import { WorkflowService } from '../../../services/app-state/workflow.service';

@UntilDestroy()
@Component({
  selector: 'app-data-capturing-position-type',
  standalone: true,
  imports: [TableComponent],
  templateUrl: './data-capturing-position-type.component.html',
})
export class DataCapturingPositionTypeComponent implements OnInit, AfterViewInit {
  tableRows!: TableAuditPositionRow[];
  @Input({ required: true }) document!: CtDocument;
  @Output() toNextViewEmit = new EventEmitter<void>();

  @ViewChild('tableComponent')
  tableComponent?: TableComponent;

  private ocrSelectionService = inject(OcrSelectionService);
  private tableParseService = inject(TableParseService);
  private extractedDataStoreService = inject(ExtractedDataStoreService);
  private buttonStateService = inject(ButtonStateService);
  private toolBarService = inject(ToolbarService);
  protected workflowService = inject(WorkflowService);

  ngOnInit() {
    this.ocrSelectionService.selectedWordsData$
      .pipe(untilDestroyed(this))
      .subscribe((selectedWordsData) => {
        const joinedString = selectedWordsData.words.join(' ');

        if (!this.tableComponent) return;
        if (!this.tableComponent.getPropertiesOfCurrentField()) return;
        const [index, headerKey] = this.tableComponent.getPropertiesOfCurrentField()!;

        this.updateTableRows(
          this.tableParseService.handleTableHeaders(headerKey, joinedString, index),
        );
      });
    this.loadTableRows();

    this.buttonStateService.setNextButtonState({
      label: 'Weiter',
      tooltip: 'Bitte klassifiziere alle Positionen',
      isActive: () => this.areAllPositionsSet(),
      action: () => {
        this.toNextViewEmit.emit();
      },
    });

    this.toolBarService.setTableCreationEnabled(false);
  }

  ngAfterViewInit() {
    this.initPositionTypes();
  }

  private initPositionTypes() {
    const tableRows = [...this.tableRows];
    tableRows.forEach((row) => {
      if (!row.amount.value) return;
      if (row.amount.value >= 0) return;
      row.positionType.value = PositionType.CREDIT;
    });

    this.updateTableRows(tableRows);
    if (!this.tableComponent) return;
    this.tableComponent?.currentFieldIndex.set(this.getNextEmptyAuditPositionType());
  }
  private updateTableRows(tableRows: TableAuditPositionRow[]) {
    this.tableRows = tableRows;
    this.tableParseService.tableRows = tableRows;
    this.storeValues();
  }

  storeValues() {
    this.extractedDataStoreService.setAuditPositionArray(this.tableRows, this.document.id);
  }

  private loadTableRows(): void {
    const auditPositions = this.extractedDataStoreService.auditPositionArray.filter(
      (position) => position.documentId === this.document.id,
    );
    if (auditPositions.length <= 0) {
      return;
    }
    this.updateTableRows(
      auditPositions.map((auditPosition) => ({
        description: {
          value: auditPosition.description.value,
          id: auditPosition.description.id,
        },
        from: {
          value: auditPosition.from.value,
          id: auditPosition.from.id,
        },
        to: {
          value: auditPosition.to.value,
          id: auditPosition.to.id,
        },
        amount: {
          value: auditPosition.originalAmount.value,
          id: auditPosition.originalAmount.id,
        },
        positionType: {
          value: auditPosition.type.value,
          id: auditPosition.type.id,
        },
        tableId: auditPosition.tableId,
        pageId: auditPosition.pageId,
      })),
    );
  }

  areAllPositionsSet(): boolean {
    return this.tableRows.every((row) => {
      return row.positionType.value !== undefined;
    });
  }

  setRowDataAtIndex(event: Event, header: string, index: number): void {
    const input = event.target as HTMLInputElement;

    this.updateTableRows(this.tableParseService.handleTableHeaders(header, input.value, index));
  }

  setDropdownValueAtIndex(positionType: PositionType, header: string, rowIndex: number) {
    this.updateTableRows(this.tableParseService.handleTableHeaders(header, positionType, rowIndex));

    if (!this.tableComponent) return;

    const nextIndex = this.getNextEmptyAuditPositionType(rowIndex);

    this.tableComponent?.currentFieldIndex.set(nextIndex);
  }

  private getNextEmptyAuditPositionType(rowIndex = 0): number | undefined {
    const nextRow = this.tableRows.slice(rowIndex).find((row) => {
      return !row.positionType.value;
    });
    const originalRowIndex = this.tableRows.findIndex((row) => {
      return row === nextRow;
    });
    if (originalRowIndex < 0) return undefined;
    return (
      originalRowIndex * positionClassificationHeaders.length +
      this.positionClassificationHeaders.findIndex((header) => {
        return header === tableHeaderConstants.type;
      })
    );
  }

  protected readonly positionClassificationHeaders = positionClassificationHeaders;
  protected inputTypes = {
    [tableHeaderConstants.type]: InputType.DROPDOWN,
    [tableHeaderConstants.description]: InputType.INPUT,
    [tableHeaderConstants.amount]: InputType.INPUT,
  };
  protected readonly AppView = AppView;
}

<div
  class="group/edit absolute z-30 border-2 border-theme-blue"
  [ngClass]="{ 'no-pointer': tableData.confirmed || !isTableCreationToggled }"
  [ngStyle]="tableRect | scaleOverlayRect: page.id | rectDisplayStyle"
>
  @for (line of tableData.lines.vertical.concat(tableData.lines.horizontal); track $index) {
    <app-table-overlay-line
      [hideEventtarget]="tableData.layoutDefined || tableData.confirmed"
      [line]="line"
      [tableRef]="table"
      [tableRect]="tableRect"
      (delete)="this.deleteLine(line)"
      (selected)="
        this.pickLines({
          line,
          index:
            $index < tableData.lines.vertical.length
              ? $index
              : $index - tableData.lines.vertical.length,
        })
      "
      (mouseenter)="arePreviewLinesEnabled = false"
      (mouseleave)="arePreviewLinesEnabled = true"
    />
  }
  <app-table-overlay-move-intersection
    [tableData]="tableData"
    (setArePreviewLinesEnabled)="arePreviewLinesEnabled = $event"
    (mouseDown)="
      pickLines(
        { line: $event.verticalLine, index: $event.verticalIndex },
        { line: $event.horizontalLine, index: $event.horizontalIndex }
      )
    "
    [tableRect]="tableRect"
    [tableRef]="table"
  />
  <div
    (mouseenter)="arePreviewLinesEnabled = true"
    (mouseleave)="arePreviewLinesEnabled = false"
    (mousedown)="$event.preventDefault(); addLineAtPosition(); $event.stopPropagation()"
    (contextmenu)="$event.preventDefault()"
    class="relative top-0 h-full w-full cursor-crosshair"
    #table
  >
    <app-table-overlay-preview-lines
      [tableData]="tableData"
      [tableRect]="tableRect"
      [tableRef]="table"
      [arePreviewLinesEnabled]="arePreviewLinesEnabled"
      [isAltPressed]="isAltPressed"
      [isCtrlPressed]="isCtrlPressed"
      [lassoSelection]="lassoSelection"
      [currentDragInfos]="currentDragInfos"
    />
    <div class="flex h-full w-full flex-col items-start">
      @for (horizontalLine of tableData.lines.horizontal.slice(0, -1); track $index) {
        <app-table-overlay-row
          [tableData]="tableData"
          [rowIndex]="$index"
          [isIgnored]="ignoredRowIndices.has($index)"
          (toggleIgnoreForRow)="toggleRowDisplay($index)"
        />
      }
    </div>
    @if (tableData.layoutDefined || tableData.confirmed) {
      <app-table-overlay-column-assignment
        [tableData]="tableData"
        [table]="table"
        [dimension]="dimension"
        (newTableHeaderAtColumnPosition)="
          setNewTableHeaderAtColumnPosition($event.colPos, $event.newHeader)
        "
      />
    }
  </div>
  @if (isTableExtractionView) {
    <app-table-overlay-context-menu
      [tableData]="tableData"
      (deleteButtonClicked)="openDeletionDialog()"
      (editButtonClicked)="openEditDialog()"
      (magicWandButtonClicked)="detectTable()"
    />
  }

  @if (isTableCreationToggled && !tableData.confirmed) {
    @if (tableData.layoutDefined) {
      <app-table-overlay-back-button
        class="absolute left-0 top-full translate-y-2"
        [tableData]="tableData"
        (buttonClicked)="undoLayoutDefined()"
      />
    }

    <app-table-overlay-confirm-button
      class="absolute right-0 top-full translate-y-2"
      [tableData]="tableData"
      (buttonClicked)="confirmButtonLogic()"
    />
  }
</div>
@if (highlight) {
  <div
    class="absolute bg-primary opacity-30"
    [ngStyle]="highlight | scaleOverlayRect: page.id | rectDisplayStyle"
    #tableHighlight
  ></div>
}
<app-edit-dialog #editDialog [tableData]="tableData" (acceptedDialog)="editDialogConfirmLogic()" />
<app-deletion-dialog
  #deletionDialog
  [tableData]="tableData"
  (acceptedDialog)="deleteDialogConfirmLogic()"
/>

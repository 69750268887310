import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { WaIntersectionObserver } from '@ng-web-apis/intersection-observer';
import { AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { FitType } from '../../../../models/interfaces';
import { CtDocument, CtPage } from '../../../../models/ct-batch-model';
import { LoadImagePipe, PageImageService } from '../../../../services/api/page-image.service';

@Component({
  selector: 'app-document-page-preview',
  standalone: true,
  imports: [MatIcon, WaIntersectionObserver, LoadImagePipe, AsyncPipe],
  templateUrl: './document-page-preview.component.html',
  styleUrl: './document-page-preview.component.scss',
})
export class DocumentPagePreviewComponent {
  @Input({ required: true }) document!: CtDocument;
  @Input({ required: true }) page!: CtPage;
  @Input({ required: true }) fitType!: FitType;

  @Output() pageIntersection: EventEmitter<{ documentType: string; pageId: string }> =
    new EventEmitter<{ documentType: string; pageId: string }>();

  @Input({ required: true }) zoom!: number;

  protected pageImageService = inject(PageImageService);
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  InputType,
  PositionType,
  TableAuditPositionRow,
  tableHeaderConstants,
} from '../../../../models/interfaces';
import { TooltipModule } from 'primeng/tooltip';
import { NgClass } from '@angular/common';
import { LocalizedDatePipe } from '../../../../pipes/localized-date.pipe';
import { getWidthForInput } from '../../../../utils/DataCapturingTableWidth.utils';
import { getDefaultInputClasses } from '../../../../utils/InputValidation.utils';
import dayjs from 'dayjs';
import { MatTooltip } from '@angular/material/tooltip';
import { formatMoney } from '../../../../utils/FormatMoney.utils';
import { EditTableLayoutComponent } from './edit-table-layout/edit-table-layout.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { getTranslatedAlphabeticalSortedPositionTypes } from '../../../../utils/utils';

@Component({
  selector: 'app-table-item',
  standalone: true,
  imports: [TooltipModule, NgClass, MatTooltip, EditTableLayoutComponent, DropdownComponent],
  templateUrl: './table-item.component.html',
  styleUrl: './table-item.component.scss',
})
export class TableItemComponent {
  @Input({ required: true }) tableHeader: string[] = [];
  @Input({ required: true }) row!: TableAuditPositionRow;
  @Input({ required: true }) rowIndex!: number;
  @Input({ required: true }) currentFieldIndex!: number | undefined;
  @Input({ required: true }) topAndBottomAddLineControls = false;
  @Input({ required: true }) isTableLayoutEditable!: boolean;
  @Input({ required: true }) inputTypes!: Record<string, InputType>;

  @Output() deleteRowAtIndex = new EventEmitter<number>();
  @Output() addRowAtIndex = new EventEmitter<{
    index: number;
    tableId: string | undefined;
    pageId: string | undefined;
  }>();

  @Output() newCurrentFieldIndex = new EventEmitter<number>();
  @Output() focusOutInput = new EventEmitter<{ event: Event; header: string; rowIndex: number }>();

  @Output() dropDownValueSet = new EventEmitter<{
    positionType: PositionType;
    header: string;
    rowIndex: number;
  }>();

  protected readonly getWidthForInput = getWidthForInput;

  getInputType(header: string) {
    switch (header) {
      default:
        return 'text';
    }
  }

  protected onAddRow(index: number, row: TableAuditPositionRow) {
    this.addRowAtIndex.emit({ index, tableId: row.tableId, pageId: row.pageId });
  }

  getCellData(row: TableAuditPositionRow, header: string) {
    switch (header) {
      case tableHeaderConstants.from:
        if (!row.from.value) return '';
        if (!dayjs(row.from.value).isValid()) {
          return row.from.valueAsString;
        }
        return new LocalizedDatePipe().transform(row.from.value);
      case tableHeaderConstants.to:
        if (!row.to.value) return '';
        if (!dayjs(row.to.value).isValid()) {
          return row.to.valueAsString;
        }
        return new LocalizedDatePipe().transform(row.to.value);
      case tableHeaderConstants.amount:
        if (!row.amount.value) return '';
        if (isNaN(row.amount.value)) {
          row.amount.validationError = 'Invalides Zahlenformat. Bitte nutze das Format 1234,56.';
          return row.amount.value;
        }
        return formatMoney(row.amount.value);
      default:
        if (!row.description.value) return '';
        return row.description.value;
    }
  }

  getTooltipData(row: TableAuditPositionRow, header: string) {
    switch (header) {
      case tableHeaderConstants.from:
        return row.from.validationError;
      case tableHeaderConstants.to:
        return row.to.validationError;
      case tableHeaderConstants.amount:
        return row.amount.validationError;
      default:
        return row.description.validationError;
    }
  }

  getInputClasses(
    row: TableAuditPositionRow,
    rowIndex: number,
    currentFieldIndex: number | undefined,
    headerIndex: number,
    header: string,
  ) {
    const isCurrentInput =
      this.currentFieldIndex === rowIndex * this.tableHeader.length + headerIndex;
    let displayError: boolean;
    let value: unknown;

    switch (header) {
      case tableHeaderConstants.from:
        value = row.from.value;
        displayError = !!row.from.validationError;
        break;
      case tableHeaderConstants.to:
        value = row.to.value;
        displayError = !!row.to.validationError;
        break;
      case tableHeaderConstants.amount:
        value = row.amount.value;
        displayError = !!row.amount.validationError;
        break;
      default:
        value = row.description.value;
        displayError = !!row.description.validationError;
        break;
    }

    return getDefaultInputClasses(value, isCurrentInput, displayError);
  }

  positionTypes = getTranslatedAlphabeticalSortedPositionTypes();

  protected readonly tableHeaderConstants = tableHeaderConstants;
  protected readonly InputType = InputType;
}

<div class="flex h-full min-h-0 w-full gap-4">
  <app-document-preview
    class="w-0 flex-1"
    [documents]="ctBatchStoreService.ctBatch.documents"
    [singleOpenedDocument]="currentDocument"
  />
  <app-data-capturing-position-type
    class="flex-1"
    [document]="currentDocument"
    (toNextViewEmit)="goToNextView()"
  />
</div>

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
  WritableSignal,
} from '@angular/core';
import { CtDocument, CtPage } from '../../models/ct-batch-model';
import { PreviewToolBarComponent } from './preview-toolbar/preview-tool-bar/preview-tool-bar.component';
import { DocumentPagePreviewComponent } from './image-preview/document-page-preview/document-page-preview.component';
import { WaIntersectionObserver } from '@ng-web-apis/intersection-observer';
import { OcrOverlayComponent } from '../overlay/ocr-overlay.component';
import { LassoSelectionDirective } from '../../directives/lasso-selection.directive';
import { ImageListPreviewComponent } from './image-list-preview/image-list-preview.component';
import { NgClass, NgStyle } from '@angular/common';
import { ButtonStateService } from '../../services/app-state/button-state.service';
import { ToolbarService } from '../../services/app-state/toolbar.service';
import { FitType } from '../../models/interfaces';
import { WorkflowService } from '../../services/app-state/workflow.service';
import { MatIcon } from '@angular/material/icon';
import { TableOverlayStoreService } from '../../services/overlay/table-overlay-store.service';

@Component({
  selector: 'app-document-preview',
  standalone: true,
  templateUrl: './document-preview.component.html',
  imports: [
    PreviewToolBarComponent,
    DocumentPagePreviewComponent,
    WaIntersectionObserver,
    OcrOverlayComponent,
    LassoSelectionDirective,
    ImageListPreviewComponent,
    NgClass,
    MatIcon,
    NgStyle,
  ],
})
export class DocumentPreviewComponent implements OnInit, OnChanges {
  protected readonly toolbarService = inject(ToolbarService); //
  protected readonly buttonStateService = inject(ButtonStateService); //

  protected readonly workflowService = inject(WorkflowService); //
  //TODO: maybe input the data?
  protected readonly tableOverlayStoreService = inject(TableOverlayStoreService);

  @Input({ required: true }) documents!: CtDocument[];

  /**
   * When the user enables the "showAllDocuments" mode, we want to show all the documents in the
   * batch, otherwise the view may show only the pages of one document.
   * For example, during table extraction, the user can toggle between displaying only
   * the current "forderungsaufstellung" and showing all documents.
   */
  @Input() singleOpenedDocument?: CtDocument;

  @Output() openPrevDocumentEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('pageImageContainer') pageImageContainer!: ElementRef<HTMLDivElement>;
  @ViewChildren('ocrOverlayComponent') ocrOverlayComponents!: QueryList<OcrOverlayComponent>;

  protected sortedDocuments!: CtDocument[];
  protected currentPageIndex = 0;
  protected currentZoom = 1;
  protected fitType: WritableSignal<FitType> = signal(FitType.HEIGHT);
  protected isPreviewListExpanded = false;
  protected isPreviewListHovered = false;
  protected selectedDocumentId!: string;

  ngOnInit(): void {
    this.buttonStateService.setPrevButtonState({
      label: 'Zurück',
      isActive: () => !this.workflowService.isFirstViewInQueue(),
      action: () => {
        this.goToPrevView();
      },
    });

    this.sortedDocuments = this.sortDocuments();
  }

  ngOnChanges(): void {
    this.sortedDocuments = this.sortDocuments();
  }

  @HostListener('wheel', ['$event'])
  protected onScroll(event: WheelEvent) {
    if (event.ctrlKey) {
      const zoomMultiplier = 0.002; //50x slower, because it's way too fast
      const zoomAmount = -event.deltaY * zoomMultiplier;
      this.onChangeZoom(zoomAmount);
      event.preventDefault();
    }
  }

  @HostListener('window:keyup', ['$event'])
  protected onKeyUp(event: KeyboardEvent) {
    if (event.code === 'Escape' && !this.doUnconfirmedTablesExist()) {
      this.toolbarService.setTableCreationEnabled(false);
    }
  }

  get allPages() {
    return this.sortedDocuments.flatMap((document) => document.pages);
  }

  sortDocuments(): CtDocument[] {
    // If there is a singleOpenedDocument, we want to sort it to the top.
    const compareValue = (document: CtDocument) =>
      document == this.singleOpenedDocument
        ? Number.NEGATIVE_INFINITY
        : this.getDocumentTypeComparisonValue(document.documentClass);

    return this.documents.toSorted(
      (document1, document2) =>
        compareValue(document1) - compareValue(document2) ||
        document1.documentClass.localeCompare(document2.documentClass),
    );
  }

  private getDocumentTypeComparisonValue(documentType: string) {
    // For now: Sort Forderungsaufstellung to front and Sonstiges to the end.
    return documentType === 'Forderungsaufstellung'
      ? 0
      : documentType === 'Sonstiges'
        ? 99999999
        : 1;
  }

  protected scrollToPage(index: number) {
    const imageSection = this.getPageImageSection(index);
    imageSection.scrollIntoView({
      block: 'start',
      behavior: 'instant',
    });
  }

  private getPageImageSection(index: number): HTMLElement {
    return this.pageImageContainer.nativeElement.children[index] as HTMLElement;
  }

  protected onChangeZoom(amount: number) {
    const MAX_ZOOM = 5;
    const MIN_ZOOM = 0.25;
    const container = this.pageImageContainer.nativeElement;

    const relativeXBefore =
      (container.scrollLeft + container.clientWidth * 0.5) / container.scrollWidth;
    const relativeYBefore =
      (container.scrollTop + container.clientHeight * 0.5) / container.scrollHeight;

    this.currentZoom = Math.max(Math.min(this.currentZoom + amount, MAX_ZOOM), MIN_ZOOM);

    setTimeout(() => {
      const scrollLeft = relativeXBefore * container.scrollWidth - 0.5 * container.clientWidth;
      const scrollTop = relativeYBefore * container.scrollHeight - 0.5 * container.clientHeight;
      container.scrollTo({
        left: scrollLeft,
        top: scrollTop,
      });
    });
  }

  protected onToggleFit() {
    this.setFitType((oldType) => (oldType === FitType.WIDTH ? FitType.HEIGHT : FitType.WIDTH));
    setTimeout(() => {
      this.scrollToPage(this.currentPageIndex);
    });
    this.currentZoom = 1;
  }

  private setFitType(updateFunction: (oldType: FitType) => FitType) {
    this.fitType.update((current) => updateFunction(current));
  }

  private getPage(index: number): CtPage {
    return this.allPages[index];
  }

  private get currentPage(): CtPage {
    return this.getPage(this.currentPageIndex);
  }

  onPageImageScrolledIntoView(pageId: string) {
    this.currentPageIndex = this.sortedDocuments
      .flatMap((document) => document.pages)
      .findIndex((page) => {
        return page.id === pageId;
      });

    this.selectedDocumentId = this.sortedDocuments.find((document) =>
      document.pages.find((page) => page.id === pageId),
    )!.id;
  }

  goToPrevView() {
    const areAllTablesConfirmed = this.ocrOverlayComponents
      .map((ocrOverlayComponent) => ocrOverlayComponent.getTableCollection())
      .flatMap((tableCollection) => tableCollection.map((table) => table.confirmed))
      .every((bool) => bool);

    if (areAllTablesConfirmed) {
      if (
        this.workflowService.isTableExtractionView() &&
        this.workflowService.currentForderungsaufstellung$.value !== 0
      )
        this.workflowService.setAndStoreCurrentForderungsaufstellung(
          this.workflowService.currentForderungsaufstellung$.value - 1,
        );
      this.workflowService.navigateToPrevView();
    }
  }

  getPageContainerStyle() {
    const zoomedPercent = this.currentZoom * 100;
    const zoomedCalcValue = `${zoomedPercent}%`;
    const baseLength = this.fitType() === FitType.WIDTH ? 'width' : 'height';
    return {
      [baseLength]: zoomedCalcValue,
    };
  }

  areTablesOnCurrentPage() {
    //TODO page should not be able to be undefined
    if (!this.currentPage) return false;
    return this.tableOverlayStoreService.getTablesOnPage(this.currentPage.id).length > 0;
  }

  doUnconfirmedTablesExist(): boolean {
    return this.tableOverlayStoreService.getAllTables().some((table) => !table.confirmed);
  }

  getAspectRatio() {
    // if (!this.image?.nativeElement.naturalHeight) {
    //   return 1;
    // }
    //
    // return this.image.nativeElement.naturalWidth / this.image.nativeElement.naturalHeight;
    return 0.7 / 1;
  }

  protected isSingleDocumentView() {
    return (
      this.workflowService.isTableExtractionView() &&
      !this.toolbarService.isShowAllDocumentsEnabled()
    );
  }
}

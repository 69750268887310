import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MenuModule } from 'primeng/menu';
import { RectDisplayStylePipe } from '../../overlay-rect/rect-display-style.pipe';
import { TableOverlayLineComponent } from './table-overlay-line/table-overlay-line.component';
import {
  AuditPositionWithFieldIndex,
  Dimension2d,
  Interval,
  Key,
  LineOrientation,
  NOT_IMPORTANT_TABLE_HEADER,
  TableAuditPositionRow,
  TableData,
  tableHeaderConstants,
  TableOverlayLine,
  WordRect,
} from '../../../../models/interfaces';
import { NgClass, NgStyle } from '@angular/common';
import { LassoSelectionDirective } from '../../../../directives/lasso-selection.directive';
import { Confirmation, ConfirmationService, SharedModule } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TableOverlayStoreService } from '../../../../services/overlay/table-overlay-store.service';
import { DeletionDialogComponent } from '../../../dialogs/deletion-dialog/deletion-dialog.component';
import { EditDialogComponent } from '../../../dialogs/edit-dialog/edit-dialog.component';
import { TableOverlayConfirmButtonComponent } from './table-overlay-confirm-button/table-overlay-confirm-button.component';
import { TableOverlayContextMenuComponent } from './table-overlay-context-menu/table-overlay-context-menu.component';
import { TableOverlayColumnAssignmentComponent } from './table-overlay-column-assignment/table-overlay-column-assignment.component';
import { TableOverlayMoveIntersectionComponent } from './table-overlay-move-intersection/table-overlay-move-intersection.component';
import { TableOverlayPreviewLinesComponent } from './table-overlay-preview-lines/table-overlay-preview-lines.component';
import { TableOverlayRowComponent } from './table-overlay-row/table-overlay-row.component';
import { ScaleOverlayRectPipe } from '../../overlay-rect/scale-overlay-rect.pipe';
import { OcrTableService } from '../../../../services/ocr/ocr-table.service';
import { ExtractedTableCreationService } from '../../../../services/extracted-data/extracted-table-creation.service';
import { ToolbarService } from '../../../../services/app-state/toolbar.service';
import { OcrService } from '../../../../services/ocr/ocr.service';
import { TableDetectionApiService } from '../../../../services/api/table-detection-api.service';
import { ExtractedDataStoreService } from '../../../../services/extracted-data/extracted-data-store.service';
import { ExtractedTableRemovalService } from '../../../../services/extracted-data/extracted-table-removal.service';
import { OverlayDimensionService } from '../../../../services/overlay/overlay-dimension.service';
import { TableOverlayBackButtonComponent } from './table-overlay-back-button/table-overlay-back-button.component';
import { transformDate } from '../../../../pipes/ocr-date.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { sanitizeDateInput } from '../../../../utils/SanitizingInput.utils';
import { parseCurrency } from '../../../../utils/ParseCurrency.utils';
import { ConfirmService } from '@l21s-ecnps/gui-commons';
import { CtPage } from '../../../../models/ct-batch-model';

@UntilDestroy()
@Component({
  selector: 'app-table-overlay',
  standalone: true,
  imports: [
    MenuModule,
    RectDisplayStylePipe,
    TableOverlayLineComponent,
    NgStyle,
    NgClass,
    ConfirmDialogModule,
    SharedModule,
    DeletionDialogComponent,
    EditDialogComponent,
    TableOverlayConfirmButtonComponent,
    TableOverlayContextMenuComponent,
    TableOverlayColumnAssignmentComponent,
    TableOverlayMoveIntersectionComponent,
    TableOverlayPreviewLinesComponent,
    TableOverlayRowComponent,
    ScaleOverlayRectPipe,
    TableOverlayBackButtonComponent,
  ],
  templateUrl: './table-overlay.component.html',
  styleUrl: './table-overlay.component.scss',
})
export class TableOverlayComponent implements OnInit, OnChanges {
  protected lassoSelection: LassoSelectionDirective = inject(LassoSelectionDirective);
  private confirmationService = inject(ConfirmationService); //
  protected toolbarService = inject(ToolbarService); //
  private tableDetectionApiService = inject(TableDetectionApiService); //

  private ocrTableService = inject(OcrTableService); // useful to create table data
  private ocrService = inject(OcrService); // useful for magic wand

  private extractedDataStoreService = inject(ExtractedDataStoreService); //
  protected extractedTableRemovalService = inject(ExtractedTableRemovalService); //
  private extractedTableCreationService = inject(ExtractedTableCreationService); //

  private tableOverlayStoreService = inject(TableOverlayStoreService); //
  private overlayDimensionService = inject(OverlayDimensionService); //

  private confirmService = inject(ConfirmService);

  @Input() isTableCreationToggled!: boolean;
  @Input({ required: true }) tableData!: TableData;
  @Input({ required: true }) page!: CtPage;
  @Input({ required: true }) isTableExtractionView!: boolean;
  @Input({ required: true }) highlight!: WordRect | undefined;

  @Output() deleteTable = new EventEmitter<string>();
  @Output() storeTableData = new EventEmitter<{
    pageId: string;
    tableData: TableData;
  }>();

  @ViewChild('deletionDialog') deletionDialog!: Confirmation;
  @ViewChild('editDialog') editDialog!: Confirmation;
  @ViewChild('tableHighlight') tableHighlight!: ElementRef<HTMLDivElement>;

  protected currentDragInfos: { line: TableOverlayLine; offsetRestriction: Interval }[] = [];
  protected keyDownStatus: Record<Key, boolean> = {
    [Key.ALT]: false,
    [Key.CTRL]: false,
    [Key.ESCAPE]: false,
  };
  arePreviewLinesEnabled = true;
  ignoredRowIndices: Set<number> = new Set<number>();
  dimension!: Dimension2d;
  protected extractedTableData: AuditPositionWithFieldIndex[] = [];

  constructor() {
    this.initializeLineMovement();
  }

  ngOnInit() {
    this.overlayDimensionService.dimensionsByPage$
      .pipe(untilDestroyed(this))
      .subscribe((dimension) => {
        this.dimension = dimension[this.page.id];
      });

    this.extractedTableData = this.extractedDataStoreService.auditPositionArray.filter(
      (auditPosition) =>
        auditPosition.pageId === this.page.id && auditPosition.tableId === this.tableData.id,
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['highlight']) return;
    if (!changes['highlight'].currentValue) return;

    setTimeout(() => {
      this.tableHighlight.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }

  @HostListener('window:mouseup') onMouseUp() {
    if (this.currentDragInfos.length > 0) this.dropLines();
  }

  @HostListener('window:keydown', ['$event'])
  protected handleKeyDownEvent(event: KeyboardEvent) {
    if (event.altKey) event.preventDefault();
    this.updateKeyDownStatus(event.key, true);
  }

  @HostListener('window:keyup', ['$event'])
  protected handleKeyUpEvent(event: KeyboardEvent) {
    this.updateKeyDownStatus(event.key, false);
  }

  protected get tableRect(): WordRect {
    return {
      x: [
        this.tableData.lines.vertical.at(0)!.offset,
        this.tableData.lines.vertical.at(-1)!.offset,
      ],
      y: [
        this.tableData.lines.horizontal.at(0)!.offset,
        this.tableData.lines.horizontal.at(-1)!.offset,
      ],
    };
  }

  protected isInInterval(value: number, interval: Interval) {
    return value >= interval[0] && value <= interval[1];
  }

  private initializeLineMovement() {
    effect(() => {
      const mousePosition = this.lassoSelection.currentMousePosition();

      if (this.currentDragInfos.length === 0) {
        return;
      }

      for (const { line, offsetRestriction } of this.currentDragInfos) {
        if (
          line.orientation === LineOrientation.VERTICAL &&
          !this.isInInterval(mousePosition[0], offsetRestriction)
        ) {
          return;
        }

        if (
          line.orientation === LineOrientation.HORIZONTAL &&
          !this.isInInterval(mousePosition[1], offsetRestriction)
        ) {
          return;
        }

        line.offset =
          line.orientation === LineOrientation.VERTICAL ? mousePosition[0] : mousePosition[1];
      }
    });
  }

  protected addLineAtPosition(): void {
    if (this.tableData.layoutDefined) return;

    const currentMousePosition = this.lassoSelection.currentMousePosition();
    const verticalLine: TableOverlayLine = {
      orientation: LineOrientation.VERTICAL,
      offset: currentMousePosition[0],
    };
    const horizontalLine: TableOverlayLine = {
      orientation: LineOrientation.HORIZONTAL,
      offset: currentMousePosition[1],
    };

    if (this.isCtrlPressed || this.isAltPressed) {
      const targetLines = this.tableData.lines.vertical;

      const insertionIndex = targetLines.findIndex((l) => l.offset > verticalLine.offset);
      targetLines.splice(insertionIndex, 0, verticalLine);

      this.adjustColumnHeaderAfterNewLine(insertionIndex);
    }
    if (!this.isCtrlPressed || this.isAltPressed) {
      const targetLines = this.tableData.lines.horizontal;

      const insertionIndex = targetLines.findIndex((l) => l.offset > horizontalLine.offset);
      targetLines.splice(insertionIndex, 0, horizontalLine);
    }

    this.emitStoreTableData();
  }

  protected dropLines() {
    this.emitStoreTableData();
    this.currentDragInfos = [];
  }

  protected deleteLine(line: TableOverlayLine) {
    const sourceArray = this.getLines(line.orientation);
    const lineIndex = sourceArray.findIndex((l) => l === line);

    this.adjustColumnHeadersAfterLineDelete(lineIndex);
    sourceArray.splice(lineIndex, 1);
    this.currentDragInfos = [];
    this.arePreviewLinesEnabled = true;
    this.emitStoreTableData();
  }

  protected pickLines(...linesWithIndices: { line: TableOverlayLine; index: number }[]) {
    this.currentDragInfos = [];
    for (const { line, index } of linesWithIndices) {
      const lines = this.getLines(line.orientation);
      const previousLine = index > 0 ? lines[index - 1] : undefined;
      const nextLine = index < lines.length - 1 ? lines[index + 1] : undefined;
      this.currentDragInfos.push({
        line,
        offsetRestriction: [previousLine?.offset ?? 0, nextLine?.offset ?? 1],
      });
    }
  }

  protected getLines(orientation: LineOrientation) {
    return orientation === LineOrientation.VERTICAL
      ? this.tableData.lines.vertical
      : this.tableData.lines.horizontal;
  }

  private updateKeyDownStatus(key: string, isKeyDown: boolean) {
    if (Object.hasOwn(this.keyDownStatus, key)) {
      this.keyDownStatus[key as Key] = isKeyDown;
    }
  }

  protected get isCtrlPressed() {
    return this.isKeyDown(Key.CTRL);
  }

  protected get isAltPressed() {
    return this.isKeyDown(Key.ALT);
  }

  private isKeyDown(key: Key) {
    return this.keyDownStatus[key];
  }

  private emitStoreTableData() {
    this.storeTableData.emit({
      pageId: this.page.id,
      tableData: this.tableData,
    });
    this.tableOverlayStoreService.storeTableOverlayAtPage(this.page.id, this.tableData);
  }

  toggleRowDisplay(rowIndex: number) {
    this.ignoredRowIndices.add(rowIndex);
  }

  confirmButtonLogic() {
    if (!this.tableData.layoutDefined) {
      this.tableData.layoutDefined = true;
      this.preFillColumnHeaders();
    } else {
      this.createTableData();
      this.tableData.confirmed = true;
      this.toolbarService.setTableCreationEnabled(false);
    }

    this.emitStoreTableData();
  }

  private preFillColumnHeaders() {
    if (this.tableData.columnHeaders.length > 0) return;

    this.tableData.columnHeaders = Array<string | undefined>(
      this.tableData.lines.vertical.length - 1,
    ).fill(undefined);
  }

  undoLayoutDefined() {
    this.tableData.layoutDefined = false;
    this.emitStoreTableData();
  }

  private adjustColumnHeaderAfterNewLine(insertionIndex: number) {
    if (this.tableData.columnHeaders.length === 0) return;

    this.tableData.columnHeaders[insertionIndex - 1] = undefined;
    this.tableData.columnHeaders.splice(insertionIndex, 0, undefined);
  }

  private adjustColumnHeadersAfterLineDelete(lineIndex: number) {
    if (this.tableData.columnHeaders.length === 0) return;

    this.tableData.columnHeaders[lineIndex - 1] = undefined;
    this.tableData.columnHeaders.splice(lineIndex, 1);
  }

  private createTableData() {
    const tableRows = this.ocrTableService.getWordsInTable(
      this.page,
      this.tableData.lines.vertical.map((line) => line.offset),
      this.tableData.lines.horizontal.map((line) => line.offset),
    );

    const columnHeaderIndices = {
      Von: this.tableData.columnHeaders.findIndex((header) => header === tableHeaderConstants.from),
      Bis: this.tableData.columnHeaders.findIndex((header) => header === tableHeaderConstants.to),
      Betrag: this.tableData.columnHeaders.findIndex(
        (header) => header === tableHeaderConstants.amount,
      ),
      Beschreibung: this.tableData.columnHeaders.findIndex(
        (header) => header === tableHeaderConstants.description,
      ),
    };

    const labelledTableRows: TableAuditPositionRow[] = [];
    for (let index = 0; index < tableRows.length; index++) {
      if (this.ignoredRowIndices.has(index)) continue;

      const row = tableRows[index];
      const sanitizedVon =
        columnHeaderIndices.Von !== -1
          ? sanitizeDateInput(row[columnHeaderIndices.Von].value)
          : undefined;
      const sanitizedBis =
        columnHeaderIndices.Bis !== -1
          ? sanitizeDateInput(row[columnHeaderIndices.Bis].value)
          : undefined;
      const sanitizedVonAsDate = sanitizedVon ? transformDate(sanitizedVon).toDate() : undefined;
      const sanitizedBisAsDate = sanitizedBis ? transformDate(sanitizedBis).toDate() : undefined;

      labelledTableRows.push({
        description: {
          value:
            columnHeaderIndices.Beschreibung !== -1
              ? row[columnHeaderIndices.Beschreibung].value
              : undefined,
          id: crypto.randomUUID(),
        },
        from: {
          value: sanitizedVonAsDate,
          validationError: !dayjs(sanitizedVonAsDate).isValid()
            ? 'Automatisch erfasster Wert: (' + sanitizedVon + ') ist kein Datum'
            : undefined,
          valueAsString: sanitizedVon,
          id: crypto.randomUUID(),
        },
        to: {
          value: sanitizedBisAsDate,
          validationError: !dayjs(sanitizedBisAsDate).isValid()
            ? 'Automatisch erfasster Wert: (' + sanitizedBis + ') ist kein Datum'
            : undefined,
          valueAsString: sanitizedBis,
          id: crypto.randomUUID(),
        },
        amount: {
          value:
            columnHeaderIndices.Betrag !== -1
              ? parseCurrency(row[columnHeaderIndices.Betrag].value)
              : undefined,
          id: crypto.randomUUID(),
        },
        tableId: this.tableData.id,
        pageId: this.page.id,
        positionType: { value: undefined, id: crypto.randomUUID() },
        originalTableRowIndex: index,
      });
    }

    this.extractedTableData = labelledTableRows.map((tableRow) => {
      return {
        description: { value: tableRow.description.value, id: tableRow.description.id },
        from: { value: tableRow.from.value, id: tableRow.from.id },
        to: { value: tableRow.to.value, id: tableRow.to.id },
        originalAmount: { value: tableRow.amount.value, id: tableRow.amount.id },
        tableId: tableRow.tableId,
        pageId: tableRow.pageId,
      } as AuditPositionWithFieldIndex;
    });

    this.extractedTableCreationService.createTable(labelledTableRows);
  }

  detectTable() {
    const words = this.ocrService.getWordsInRect(this.page, this.tableRect);
    const subscription = this.tableDetectionApiService
      .detectTable(words)
      .pipe(untilDestroyed(this))
      .subscribe(([columns, rows]) => {
        const innerColumnLines = columns.slice(1).map((columnOffset) => ({
          offset: columnOffset,
          orientation: LineOrientation.VERTICAL,
        }));

        const innerRowLines = rows.slice(1).map((rowOffset) => ({
          offset: rowOffset,
          orientation: LineOrientation.HORIZONTAL,
        }));

        this.tableData.lines.vertical = [
          this.tableData.lines.vertical[0],
          ...innerColumnLines,
          this.tableData.lines.vertical[this.tableData.lines.vertical.length - 1],
        ];

        this.tableData.lines.horizontal = [
          this.tableData.lines.horizontal[0],
          ...innerRowLines,
          this.tableData.lines.horizontal[this.tableData.lines.horizontal.length - 1],
        ];
      });

    this.confirmService.spinner({ subscription });
  }

  openDeletionDialog(): void {
    this.deletionDialog.key = `deletionDialog-${this.tableData.pageId}-${this.tableData.id}`;
    this.confirmationService.confirm(this.deletionDialog);
  }

  openEditDialog(): void {
    this.editDialog.key = `editDialog-${this.tableData.pageId}-${this.tableData.id}`;
    const associatedPositions = this.extractedDataStoreService.auditPositionArray.filter(
      (position) =>
        position.tableId === this.tableData.id && position.pageId === this.tableData.pageId,
    );

    if (this.isModified(associatedPositions, this.extractedTableData)) {
      this.confirmationService.confirm(this.editDialog);
      return;
    }

    this.editDialogConfirmLogic();
  }

  editDialogConfirmLogic() {
    this.toolbarService.setTableCreationEnabled(true);
    this.extractedTableRemovalService.destroyTable(this.tableData.id, this.page.id);
    this.tableData.confirmed = false;
    this.tableData.layoutDefined = false;

    this.emitStoreTableData();
  }

  private isModified(
    tablePositions: AuditPositionWithFieldIndex[],
    extractedTableData: AuditPositionWithFieldIndex[],
  ) {
    const isRowsAddedOrRemoved = tablePositions.length !== extractedTableData.length;

    if (isRowsAddedOrRemoved) {
      return true;
    }

    return tablePositions.some((position, index) => {
      const isDescriptionModified = position.description !== extractedTableData[index].description;
      const isOriginalAmountModified =
        position.originalAmount !== extractedTableData[index].originalAmount;
      const isFromDateModified =
        position.from.value?.getTime() !== extractedTableData[index].from.value?.getTime();
      const isToDateModified =
        position.to.value?.getTime() !== extractedTableData[index].to.value?.getTime();

      return (
        isDescriptionModified || isOriginalAmountModified || isFromDateModified || isToDateModified
      );
    });
  }

  setNewTableHeaderAtColumnPosition(colPos: number, newHeader: string) {
    this.setSameHeaderToUndefined(newHeader);
    this.tableData.columnHeaders[colPos] = newHeader;
  }

  private setSameHeaderToUndefined(header: string) {
    if (header !== NOT_IMPORTANT_TABLE_HEADER) {
      const columnIndexWithSameLabel = this.tableData.columnHeaders.findIndex((h) => {
        return h === header;
      });

      if (columnIndexWithSameLabel !== -1) {
        this.tableData.columnHeaders[columnIndexWithSameLabel] = undefined;
      }
    }
  }

  deleteDialogConfirmLogic() {
    this.extractedTableRemovalService.destroyTable(this.tableData.id, this.page.id);
    this.toolbarService.setTableCreationEnabled(false);
    this.deleteTable.emit(this.tableData.id);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// stores value if table creation is toggled or not (toolbar)
export class ToolbarService {
  tableCreationToggled$ = new BehaviorSubject<boolean>(true);
  showAllDocumentsEnabled = new BehaviorSubject<boolean>(false);

  public setTableCreationEnabled(toggled: boolean): void {
    this.tableCreationToggled$.next(toggled);
  }

  public isTableCreationEnabled(): boolean {
    return this.tableCreationToggled$.value;
  }

  public setShowAllDocumentsEnabled(enabled: boolean): void {
    this.showAllDocumentsEnabled.next(enabled);
  }

  public isShowAllDocumentsEnabled(): boolean {
    return this.showAllDocumentsEnabled.value;
  }
}
